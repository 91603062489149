import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import SEO from '../components/seo'


class IndexPage extends React.Component {
  render() {

    const { data } = this.props;

    return (
      <Layout>
        <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
        <h1><span>M</span>att <span>B</span>ayliss</h1>
        <p>Web Developer based in Birmingham, UK.</p>
        <p>I have been working within a mixture of digital, creative and inbound marketing agencies for the past 9 years.</p>
        <div className="social">
          <a className="linkedin" href="https://uk.linkedin.com/pub/matt-bayliss/3b/9bb/206"><Img fixed={data.image2.childImageSharp.fixed} /></a>
          <a className="twitter" href="https://twitter.com/whatsup_matt"><Img fixed={data.image1.childImageSharp.fixed} /></a>
        </div>
      </Layout>
    )
  }
}

export default IndexPage

export const squareImage = graphql`
  fragment squareImage on File {
    childImageSharp {
      fixed(width: 125, height: 125) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`
export const query = graphql`
  query {
    image1: file(relativePath: { eq: "twitter.png" }) {
      ...squareImage
    }

    image2: file(relativePath: { eq: "linkedin.png" }) {
      ...squareImage
    }
  }
`
